import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src199531276/src/mojility.ca/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <h1>{`Welcome`}</h1>
    <p>{`Mojility provides executive level and on-the ground support for managers of software teams, geared towards
building a stable and sustainable culture for your growing local workforce.`}</p>
    <p>{`Foster an internal culture of software craftsmanship. Help developers connect with the humanity and diversity on
their teams, inspiring them to build better software together. Build effective apprenticeship programs that
engage, retain and improve your current software developers.`}</p>
    <p>{`You want your team to build better software. They want to build better software. Let’s together find a way to make
that happen for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      